import React, { Component } from 'react';
import NavigationTop from './NavigationTop';
import Gvar from './Gvar';

import HomeIcon from '@material-ui/icons/Home';
import Inbox from '@material-ui/icons/Inbox';
import HistoryIcon from '@material-ui/icons/History';
import SettingIcon from '@material-ui/icons/Settings';
import Container from '@material-ui/core/Container';
import Badge from '@material-ui/core/Badge';

import Dashboard from './Dashboard';
import Histori from './Histori';
import Pesan from './Pesan';
import Pengaturan from './Pengaturan';
import Utils,{loadProfile} from './Utils';
import Logger from './Logger';
import AppBar from '@material-ui/core/AppBar';
import { BottomNavigation, BottomNavigationAction, Box, ThemeProvider, Toolbar } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Cart from './Cart';
import { ShoppingCart } from '@material-ui/icons';
const theme = createTheme({
  palette: {
    primary: {
      main: '#2E63D7',
    }
  },
});

class Home extends Component {
  state = {
    showDrawer : false,
    menuName : 'Dashboard',
    showLoading: true,
    showSnackBar: false, 
    textSnackBar :"",
    valueTab:"",
    isLoggedIn:false,
    tabActive:0,
    badge:null,
    badgeCart:null,
    bindAcc:false,
    drafTrx:[]
  }

  constructor(props){
    super(props);
    Gvar.homeContext = this;
    // Gvar.data_member = this.data_member;
  }

  handleClose = () => {
      this.setState({ showSnackBar: false });
  };

  componentDidMount(){
    try{
      Gvar.dataMember = Utils.getDataMember();
      
      // Logger.d(dataMember);
      this.data_member = Gvar.dataMember;
    }catch(err){
      this.props.history.push('/error')
      return;
    }
    this.setState({valueTab:'belanja', showLoading:false, bindAcc:localStorage.getItem('bindingAcc')});
    this.pullMessage()
    loadProfile();
    this.getDrafTrx();
  }

  getDrafTrx = (callback=()=>{}) => {
    var bodyJson = {
        start_date : Utils.formatDate(new Date()),
        end_date : Utils.formatDate(new Date()),
        no_tujuan : '',
        id_member : Gvar.dataMember.id,
        token : Utils.getMyToken(),
        status : "DRAF"
    }
    Logger.d(bodyJson);
    fetch(Gvar.server+'index.php/histori/transaksi', {
        method: 'post',
        body: JSON.stringify(bodyJson)
    })
    .then(res=>res.json())
    .then(res => {
        if(res.status_code == "200"){
          this.setState({badgeCart:res.data.length});
          callback(res.data)
        }else{
          callback([])
        }
    }).catch(err => {
      callback([])
    })
  }

  pullMessage(){

    try{
      this.data_pesan = JSON.parse(localStorage.getItem('data_pesan')==null?"[]":localStorage.getItem('data_pesan'));
        
      var bodyJson = {id_member:this.data_member.id,'token' : Utils.getMyToken()}
      
      fetch(Gvar.server+'index.php/member/tarik-pesan', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(
          res=>res.json()
        ).then(res =>{ 
            if(res.status_code == '401'){
              localStorage.setItem('is_logged_in',false);
              alert('Sesi telah habis, silakan login ulang');
              setTimeout(() => {
                Gvar.appContext.setState({isLoggedIn:false});
                window.location.reload(); 
              }, 1000);
              return;
            }
            Logger.d('Pull','Pesan Baru');
            var pesan = res.data.concat(this.data_pesan);
            if(res.data.length > 0){
              setTimeout(() => {
                Gvar.badgeContent = res.data.length;
              }, 2000);
            }

            this.setState({badge:Gvar.badgeContent})

            // Logger.d("pesan",pesan);
            localStorage.setItem('data_pesan',JSON.stringify(pesan));
            //Logger.d(res)
            //this.setState({listProduk:res})
        }).catch(err => {
            Logger.d("Pending failed");
          })
    }catch(err){
      Logger.d(err.toString());
    }
  }

  handleChange = (event, newValue) => {
    this.setState({valueTab:newValue});
  };

  componentWillMount(){
    this.setState({showLoading:true});
  }

  setBadgeCart = (total=0) => {
    this.setState({badgeCart:total});
  }

  render() {
    return (
      <>
      <ThemeProvider theme={theme}>
        <NavigationTop appContext={this} getDrafTrx={this.getDrafTrx}/>
        <Container maxWidth="sm">
          <Box my={7} style={{paddingBottom: 50}}>
            {this.state.tabActive === 0?(<Dashboard/>):null}
            {this.state.tabActive === 1?(<Histori/>):null}
            {this.state.tabActive === 2?(this.state.bindAcc?<Cart setBadgeCart={this.setBadgeCart}/>:<Pesan/>):null}
            {this.state.tabActive === 3?(<Pengaturan/>):null}
          </Box>
        </Container>
        <AppBar position="fixed" color="inherit" style={{
          top: 'auto',
          bottom: 0,
        }}>
          <Toolbar>
            <BottomNavigation
              style={{margin: '0 auto', width:'100%'}}
              value={this.state.tabActive}
              onChange={(event, newValue) => {
                this.setState({tabActive:newValue});
                if(newValue === 2){
                  setTimeout(() => {
                    this.setState({badge:null})
                    Gvar.badgeContent = null;
                  }, 1000);
                }
              }}
              showLabels
            >
              <BottomNavigationAction label="Beranda" icon={<HomeIcon/>} />
              <BottomNavigationAction label="Histori" icon={<HistoryIcon />} />
              {this.state.bindAcc?(
                <BottomNavigationAction label="Keranjang" icon={
                  <Badge badgeContent={this.state.badgeCart} color="secondary">
                    <ShoppingCart />
                  </Badge>
                } />
              ):(
                <BottomNavigationAction label="Inbox" icon={
                  <Badge badgeContent={this.state.badge} color="secondary">
                    <Inbox />
                  </Badge>
                } />
              )}
              <BottomNavigationAction label="Pengaturan" icon={<SettingIcon />} />
            </BottomNavigation>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      </>
    );
  }
}

export default (Home);
