import Gvar from './Gvar';
import Logger from './Logger';
import {HmacSHA256} from 'crypto-js';

export const loadProfile = () =>{
    var data_member = localStorage.getItem('data_member');
    var bodyJson = { id_member: JSON.parse(data_member).id,'token':Utils.getMyToken()}
    // Logger.e(bodyJson);
    fetch(Gvar.server+'index.php/member', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
    ).then(res => {
        // this.setState({list_kategori_utama:res});
        Gvar.saldo = res.data.saldo;
        Gvar.namaMember = res.data.nama;
        Gvar.idMember = res.data.id;
    }
    ).catch(err => {
        Logger.e(err.toString());
        Gvar.appContext.setState({showLoading:false});
    })
}

export function formatRupiah(angka, prefix){
    if(angka!==undefined && angka!== null && angka !== ''){
        angka = angka.toString();
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if(ribuan){
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }else{
        return '';
    }
}

// export function encr(str=''){
//     const encryptedString = cryptr.encrypt(str);
//     return encryptedString;
// }

// export function decr(str=''){
//     const decryptedString = cryptr.decrypt(str);
//     return decryptedString;
// }

export default class Utils{
    static getDataMember(){
        return JSON.parse(localStorage.getItem('data_member'));
    }
    static getMyToken(){
        return localStorage.getItem('token');
    }
    static getHash(string){
        const hmac = HmacSHA256(string, Gvar.secretMemory);
        return hmac.toString();
    }

    static formatRupiah(x=0, sparator="."){
        return 'Rp ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sparator);
    }

    static numberFormatIDPLN(x=0){
        return x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ' - ');
    }

    static formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }
}