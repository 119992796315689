import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import { BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom'
import Gvar from './Gvar';
import Logger from './Logger';
import { sha256 } from 'js-sha256';
class PLNbayar extends Component {
    constructor(props){
        super(props)
        this.detailPelanggan = (Gvar.detailPelanggan != null?Gvar.detailPelanggan:"").split(';');
    }
    state = {
        back :false,
        message : "",
        status : "GAGAL"
    }
    componentDidMount(){
       
    }

    sendTransaction (){
        Gvar.appContext.setState({showLoading:true})
        var bodyJson = Gvar.paramForPay;
        Logger.d(bodyJson);
        fetch(Gvar.server+'index.php/transaksiListrikPembayaran/topup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //Logger.d(res)
                // this.setState({listProduk:res})
                if(res.status == "SUKSES"){
                    this.setState({message:res.pesan, status:"SUKSES"});
                    Gvar.appContext.setState({ showLoading:false,showSnackBar: true, textSnackBar : 'SUKSES :)'});
                }else{
                    Gvar.appContext.setState({ showLoading:false,showSnackBar: true, textSnackBar : res.status+", "+res.pesan});
                }
            }
            ).catch(err => {
                Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
             })
    }

    render() {
        return (
            <div>
                {this.state.back?<Redirect to='/plninquiry'/>:null}
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                        {this.detailPelanggan.map((item, index) => (
                        <p key={index}>
                            {item}
                        </p>))}
                        {this.state.status=="SUKSES"?null:
                        <div>
                            <Button variant="contained" type="submit" component={Link} to={"/plninquiry"} onClick={()=>{
                                    Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Transaksi dibatalkan"});
                                }}  size="large" color="primary" style={{margin : 10}}>
                                Batal
                            </Button>
                            <Button variant="contained" type="submit" onClick={()=>{
                                    this.sendTransaction()
                                }}  size="large" color="primary" style={{margin : 10}}>
                                Bayar
                            </Button>
                        </div>}
                        {this.state.message}
                    </CardContent>
                </Card>
                </Grow>
            </div>
        );
    }
}

export default (PLNbayar);
