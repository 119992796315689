import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import { BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom'
import Gvar from './Gvar';
import TokenListrik from './TokenListrik';
import { sha256 } from 'js-sha256';
import Logger from './Logger';
class TokenListrikTopup extends Component {
    constructor(props){
        super(props)
        this.parsingDataPelanggan(Gvar.detailPelanggan);
    }
    state = {
        back :false,
        message : "",
        status : "GAGAL"
    }
    parsingDataPelanggan(params){
        //meter=86005026520;nama=YAYASAN UPAKARA;daya=S2 /5500 VA;token_unsold1=Rp. 0;token_unsold2=Rp. 0;id_pel=541102994621
        try{
            this.nama = params.split("nama=")[1].split(";")[0];
            this.meter = params.split("meter=")[1].split(";")[0];
            this.daya = params.split("daya=")[1].split(";")[0];
        }catch(err){
            alert("Sedang Gangguan :(")
            // this.setState({back:true})
        }
    }
    componentDidMount(){
       
    }

    sendTransaction (){
        Gvar.appContext.setState({showLoading:true})
        var bodyJson = Gvar.paramForTopUp;
        Logger.d(bodyJson);
        fetch(Gvar.server+'index.php/transaksiTokenListrik/topup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //Logger.d(res)
                // this.setState({listProduk:res})
                if(res.status == "SUKSES"){
                    this.setState({message:res.pesan, status:"SUKSES"});
                }
                Gvar.appContext.setState({ showLoading:false,showSnackBar: true, textSnackBar : res.status+", "+res.pesan});
            }
            ).catch(err => {
                Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
             })
    }

    render() {
        return (
            <div>
                {this.state.back?<Redirect to='/tokenlistrik'/>:null}
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                        Nama :
                        <div style={{paddingLeft:20,fontWeight:'bold',padding:3}}>{this.nama}</div>
                        Meter :
                        <div style={{paddingLeft:20,fontWeight:'bold',padding:3}}>{this.meter}</div>
                        Daya :
                        <div style={{paddingLeft:20,fontWeight:'bold',padding:3}}>{this.daya}</div>
                        {this.state.status=="SUKSES"?null:
                        <div>
                            <Button variant="contained" type="submit" component={Link} to={"/tokenlistrik"} onClick={()=>{
                                    Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Transaksi dibatalkan"});
                                }}  size="large" color="primary" style={{margin : 10}}>
                                Batal
                            </Button>
                            <Button variant="contained" type="submit" onClick={()=>{
                                    this.sendTransaction()
                                }}  size="large" color="primary" style={{margin : 10}}>
                                Kirim
                            </Button>
                        </div>}
                        {this.state.message}
                    </CardContent>
                </Card>
                </Grow>
            </div>
        );
    }
}

export default (TokenListrikTopup);
