import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Gvar from './Gvar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from './Utils';
import Logger from './Logger';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    return (
        <>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                Gvar.tabActive = 'pengaturan';
                context.props.history.goBack();
            }} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
            {context.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
        </AppBar>
        </>
    );
}

class PengaturanGantiPIN extends Component {
    
    state = {
        inputPinLama : "",
        inputPinBaru : "",
        inputPinUlang : "",
        title:'Ganti PIN'
    }
    constructor(props){
        super(props);
    }

    update(){
        var data_member = localStorage.getItem('data_member');
        var pinLama = document.getElementById("pin-lama");
        var pinBaru = document.getElementById("pin-baru");
        var pinUlang = document.getElementById("pin-ulang");
        this.setState({showLoading:true})
        var bodyJson = { 
            id_member: JSON.parse(data_member).id,
            pin_lama: Utils.getHash(pinLama.value),
            pin_baru: Utils.getHash(pinBaru.value),
            pin_baru_ulang: Utils.getHash(pinUlang.value),
            token: Utils.getMyToken()
        }
        // Logger.d(bodyJson);
        fetch(Gvar.server+'index.php/pengaturan/gantipin', {
        method: 'POST',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //Logger.d(res)
                // this.setState({listProduk:res})
                this.setState({ showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                this.setState({showLoading:false});
                this.setState({
                    inputpinLama : "",
                    inputpinBaru : "",
                    inputpinUlang : ""
                });

            }
            ).catch(err => {
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
                this.setState({showLoading:false})
             })
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    handleChangeInputPinLama = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 6);

        this.setState({
            inputPinLama : textReturn
        });
    };
    handleChangeInputPinBaru = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 6);

        this.setState({
            inputPinBaru : textReturn
        });
    };
    handleChangeInputPinUlang = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 6);

        this.setState({
            inputPinUlang : textReturn
        });
    };

    render() {
        const polygon = (
            <>
            <Card style={{marginTop : 20}}>
                <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                        <TextField
                            onChange={this.handleChangeInputPinLama}
                            value={this.state.inputPinLama}
                            id="pin-lama"
                            label="PIN Lama"
                            type="password"
                            name="nomorhp"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            onChange={this.handleChangeInputPinBaru}
                            value={this.state.inputPinBaru}
                            id="pin-baru"
                            label="PIN Baru"
                            type="password"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            onChange={this.handleChangeInputPinUlang}
                            value={this.state.inputPinUlang}
                            id="pin-ulang"
                            label="Ketik Ulang PIN Baru"
                            type="password"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <Button variant="contained" type="submit" onClick={()=>{
                                this.update()
                            }}  size="large" color="primary" style={{marginTop : 10}}>
                                Simpan
                        </Button>
                    </Grid>
                </CardContent>
            </Card> 
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{marginTop:75}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}

export default PengaturanGantiPIN;
