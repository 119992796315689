import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import { Redirect} from 'react-router-dom'
import Gvar from './Gvar';
import { sha256 } from 'js-sha256';
class PLNinquiry extends Component {
    
    constructor(props){
        super(props);
        this.state = ({
            listProduk : [],
            reditectToTopup : false
        });
    }

    componentDidMount(){
    }

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        // this.setState({ showSnackBar: false });
        // Gvar.appContext.setState({ showSnackBar: false });
    };

    sendInquiry (){
        var data_member = localStorage.getItem('data_member');
        var noTujuan = document.getElementById('inputNoTujuan').value;
        var pinTransaksi = document.getElementById('inputPINTransaksi').value;
        if(noTujuan === ""){
            Gvar.appContext.setState({ showSnackBar: true, textSnackBar : "no tujuan belum diisi"}); return;
        }
        if(pinTransaksi === ""){
            Gvar.appContext.setState({ showSnackBar: true, textSnackBar : "pin transaksi belum diisi"}); return;
        }
        Gvar.appContext.setState({showLoading:true})
        var bodyJson = { 
            id_member: JSON.parse(data_member).id,
            no_tujuan: noTujuan,
            kode_produk:'PLN',
            pin_transaksi:sha256(pinTransaksi)
        }

        console.log(bodyJson);
        fetch(Gvar.server+'index.php/transaksiListrikPembayaran/inquiry', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                // this.setState({listProduk:res})
                if(res.status === "SUKSES"){
                    Gvar.detailPelanggan = res.pesan;
                    Gvar.totalTagihan = res.total_tagihan;
                    bodyJson.total_tagihan = res.total_tagihan;
                    Gvar.paramForPay = bodyJson;
                    this.setState({reditectToTopup:true});
                    Gvar.appContext.setState({ showLoading:false});
                }else{
                    Gvar.appContext.setState({ showLoading:false,showSnackBar: true, textSnackBar : res.status+", "+res.pesan});
                }
            }
            ).catch(err => {
                Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
             })
    }

    render() {
        const polygon = (
            <div>
            <Card style={{marginTop : 20}}>
                <CardContent>
                    Pembayaran Listrik PLN
                </CardContent>
            </Card>
            <Card style={{marginTop : 20}}>
                <CardContent>
                <Grid
                    item xs={12}
                    container
                    direction="column"
                    >
                        <TextField
                            id="inputNoTujuan"
                            label="Nomor Tujuan"
                            type="number"
                            name="nomorhp"
                            autoComplete="none"
                            margin="normal"
                            variant="outlined"
                        />

                        <TextField
                            id="inputPINTransaksi"
                            label="PIN Transaksi"
                            type="password"
                            name="password"
                            margin="normal"
                            autoComplete="none"
                            variant="outlined"
                        />

                        <Button variant="contained" type="submit" onClick={()=>{
                            this.sendInquiry()
                        }}  size="large" color="primary" style={{margin : 10}}>
                            Cek ID Pelanggan
                        </Button>
                    </Grid>
                </CardContent>
            </Card>   
            </div>
          );
                    
        return (
            <div>
                {this.state.reditectToTopup?<Redirect to='/plnbayar'/>:null}
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                {polygon}
                </Grow>
            </div>
        );
    }
}

export default PLNinquiry;
