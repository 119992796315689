import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from './Gvar';
import { sha256 } from 'js-sha256';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {loadProfile} from './Utils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </div>
    );
}

class ErrorScreen extends Component {
    state = {
        title:''
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.setState({title : 'Oops'})
    }

    render() {
        // const classes = useStyles();
        const polygon = (
            <div>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                        <Grid
                            item xs={12}
                            container
                            direction="column"
                            >
                        Terjadi Kesalahan   
                        </Grid>
                    </CardContent>
                </Card> 
            </div>
          );
                    
        return (
            <div>
                {/* <AppBar position="fixed">
                    <Toolbar variant="dense">
                            {this.state.title}
                        
                    </Toolbar>
                    <Snackbar
                        style={{marginTop:56}}
                        anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                        open={this.state.showSnackBar}
                        onClose={this.handleCloseSnackBar}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.textSnackBar}</span>}
                    />   
                </AppBar> */}
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{marginTop:75}}>
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </div>
        );
    }
}


export default ErrorScreen;
