import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
class Pembayaran extends Component {

    componentDidMount(){
       
    }
    render() {
        return (
            <>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                        <Button variant="contained" component={Link} to="/plninquiry" size="large" color="primary" style={{margin : 10}}>
                            Tagihan Listrik
                        </Button>
                    </CardContent>
                </Card>
                </Grow>
            </>
        );
    }
}

export default (Pembayaran);
