import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Gvar from './Gvar';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import Utils from './Utils';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
        </AppBar>
        </div>
    );
}

class ProdukGroup extends Component {

    constructor(props){
        super(props);
        this.state = ({
            list_group :[],
            title : 'Pilih Produk'
        });
    }

    componentDidMount(){
        // this.props.appContext.setState({showLoading:false})
        this.getProdukGroup();
    }

    getProdukGroup(){
        Gvar.appContext.setState({ showSnackBar: false, textSnackBar :""});
        var currentURL = window.location.href;
        var jenis = currentURL.split("jenis=")[1];
        var bodyJson = { 
            jenis: jenis,
            id_member:Utils.getDataMember().id,
            token: Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/produk/group', {
            method: 'post',
            body: JSON.stringify(bodyJson),
        }).then(res=>res.json()
        ).then(res => {
            // console.log(res)
            this.setState({list_group:res.data}); 
        }).catch(err => {
            Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            Gvar.appContext.setState({showLoading:false})
        })
    }
    
    onClickItem(group,jenis){
        
        if(jenis==='PEMBAYARAN'){
            this.props.history.push('/produkpembayaran?group='+group+'&jenis='+jenis);
        }else{
            this.props.history.push('/produknonprefix?group='+group+'&jenis='+jenis);    
        }
    }
    
    render() {
        return (
            <div>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:56}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                        <div>
                            <Grid container style={{marginTop:10}} >
                                <Grid item xs={12}>
                                <Grid container justifyContent="flex-start">
                                    {this.state.list_group.map((item,index) => (
                                    <Grid onClick={()=>{
                                        this.onClickItem(item.group_produk,item.jenis)
                                    }} key={index} item style={{marginRight:16, marginBottom:8, width:'calc(100% / 3 - 16px)'}}>
                                        <Paper style={{padding:0}}>
                                        <img src={Gvar.pathImage+item.icon} alt={''} style={{width:'100%', height:'100%', objectFit:'contain'}}></img>
                                        </Paper>
                                        <center>
                                        <span>{item.group_produk.replace(/_/g,' ')}</span>
                                        </center>
                                    </Grid>
                                    ))}
                                </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grow>
                </ContainerLayout>
            </div>
        );
    }
}

export default (ProdukGroup);
