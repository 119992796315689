import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from './Gvar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import Utils from './Utils';
import CaptchaTextGenerator from 'react-captcha-generator';
import Logger from './Logger';
import Container from '@material-ui/core/Container';
import { Box, Typography } from '@material-ui/core';
var sha1 = require('sha1');
// import {encr} from './Utils';

function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

class Login extends Component {
    state = {
        showSnackBar : false,
        textSnackBar : '',
        disableBtnLogin : false,
        title : 'Login',
        showLoading:false,
        isLoggedIn:false,
        captchaCode:'',
        randomCaptcha:'',
        maxReCaptcha:3,
        showCaptchaCode:false,
        noHp:'',
        pass:''
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.captchaResult = this.captchaResult.bind(this);
    }

    componentDidMount(){
        var isLoggedIn = localStorage.getItem('is_logged_in');
        if(isLoggedIn == null || isLoggedIn == undefined){
            localStorage.setItem('is_logged_in','false');
            localStorage.setItem('data_member','');
            localStorage.setItem('data_pesan','[]');
        }
        this.setState({randomCaptcha:this.rand(5)});
        if(sessionStorage.getItem('passwordFailCounter') >= this.state.maxReCaptcha){
            this.setState({showCaptchaCode:true})
        }
    }

    getTimestamp(){
        var d = new Date,
        dformat = [d.getFullYear(),
            pad(d.getMonth()+1),
            pad(d.getDate())].join('-')+' '+
                [pad(d.getHours()),
                pad(d.getMinutes()),
                pad(d.getSeconds())].join(':');
               return dformat;
    }

    rand(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    login(){
        // console.log(this.state.captchaCode);
        if(this.state.showCaptchaCode){
            if(this.state.captchaCode!==this.state.inputCaptcha){
                this.setState({ showSnackBar: true, textSnackBar :"Kode Captcha salah", disableBtnLogin:false});
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                return;
            }else{
                sessionStorage.setItem('passwordFailCounter',0);
            }
        }
        if(this.state.noHp === ""){
            this.setState({ showSnackBar: true, textSnackBar :"No hp gak boleh kosong, ya", disableBtnLogin:false});
            return;
        }
        if(this.state.pass === ""){
            this.setState({ showSnackBar: true, textSnackBar :"Passwordnya belum diisi.", disableBtnLogin:false});
            return;
        }
        this.setState({showLoading:true})
        this.setState({disableBtnLogin:true});
        var timestampTmp = this.getTimestamp();
        var passHash = Utils.getHash(this.state.pass);
        var bodyJson = { no_hp: this.state.noHp,password:passHash, timestamp:timestampTmp,
        signature : sha1(timestampTmp+this.state.noHp+passHash+Gvar.secretMemory)}
        // sha1($param->timestamp.$param->no_hp.$param->password.SECRET_KEY)
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/member/login', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                this.setState({showLoading:false});
                // console.log(res)
                if(res.status === 'SUKSES'){
                    Gvar.tabActive = 'dashboard';
                    this.setState({disableBtnLogin:false}); 
                    localStorage.setItem('data_member', JSON.stringify(res.data_member));  
                    localStorage.setItem('is_logged_in', true);
                    localStorage.setItem('token', res.token);   
                    localStorage.removeItem('bindingAcc');
                    // this.setState({showSnackBar:true, textSnackBar:"Sukses Login",isLoggedIn:true});
                    Gvar.appContext.setState({isLoggedIn:true});
                }else{
                    // remove all
                    localStorage.clear();
                }
                if(res.keterangan === 'Password salah'){
                    if(sessionStorage.getItem('passwordFailCounter') === null){
                        sessionStorage.setItem('passwordFailCounter',0);
                    }
                    sessionStorage.setItem('passwordFailCounter',parseInt(sessionStorage.getItem('passwordFailCounter'))+1);
                    if(sessionStorage.getItem('passwordFailCounter') >= this.state.maxReCaptcha){
                        this.setState({showCaptchaCode:true})
                    }
                }
                this.setState({ showSnackBar: true, textSnackBar :res.keterangan, disableBtnLogin:false});
            }
            ).catch(err => {
                console.log(err.toString());
                this.setState({showLoading:false});
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", disableBtnLogin:false});
             })
    }

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    captchaResult(text) {
        this.setState({
            captchaCode: text
        })
    }

    render() {
        const polygon = (
            <>
            <Card>
                <CardContent>
                    <Grid item xs={12} container direction="column">
                        <TextField
                            id="inputNoHp"
                            label="Nomor HP"
                            type="number"
                            name="nomorhp"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                            onChange={(e)=>this.setState({noHp:e.target.value})}
                            value={this.state.noHp}
                        />

                        <TextField
                            id="inputPassword"
                            label="Password"
                            type="password"
                            name="password"
                            margin="normal"
                            variant="outlined"
                            onChange={(e)=>this.setState({pass:e.target.value})}
                            value={this.state.pass}
                        />

                        {this.state.showCaptchaCode?(
                            <>
                                <CaptchaTextGenerator result={this.captchaResult} originText={this.state.randomCaptcha} />
                                <TextField
                                    id="inputCaptcha"
                                    label="Kode Captcha"
                                    type="text"
                                    name="kode_captcha"
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e)=>this.setState({inputCaptcha:e.target.value})}
                                    value={this.state.inputCaptcha}
                                />
                            </>
                        ):null}

                        <Button disabled={this.state.disableBtnLogin} variant="contained" type="submit" onClick={()=>{
                           this.login();
                        }}  size="large" color="primary" style={{marginTop : 10}}>
                            Login
                        </Button>
                        
                        <Button disabled={this.state.disableBtnLogin} variant="contained" type="submit" onClick={()=>{
                           this.props.history.push('/daftar')
                        }}  size="large" color="primary" style={{marginTop : 10}}>
                            Daftar Gratis
                        </Button>

                        <Button disabled={this.state.disableBtnLogin} type="submit" onClick={()=>{
                           this.props.history.push('/lupapassword')
                        }}  size="large" color="primary" style={{marginTop : 10}}>
                            Lupa Password?
                        </Button>
                    </Grid>
                </CardContent>
            </Card> 
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={this.state.showSnackBar}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.textSnackBar}</span>}
            />   
            </>
          );
                    
        return (
            <>
                <AppBar position="fixed">
                    <Toolbar variant='dense'>
                        <Typography style={{margin: '0 auto'}} variant="h6" color="inherit" noWrap>
                        {this.state.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    <Container maxWidth="sm">
                        <Box my={10}>
                        {polygon}
                        </Box>
                    </Container>
                </Grow>
            </>
        );
    }
}

export default Login;
