import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Gvar from './Gvar';
import { Button } from '@material-ui/core';
import { Done } from '@material-ui/icons';

function callbackFlutter(data = [], callback = () => {}){
  try {
    window.flutterCallback.postMessage(JSON.stringify(data));
  } catch (error) {
    alert(error.toString())
  }
  callback()
}

class NavigationTop extends React.Component {
  
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    title:'Belanja'
  };

  constructor(props){
    super(props);
    Gvar.NavigationTopContext = this;
  }

  done = () => {
    this.props.getDrafTrx((dataDraf)=>{
      callbackFlutter(dataDraf, ()=>{
        console.log(dataDraf)
      });
    })
  }

  render() {

    return (
      <>
        <AppBar>
          <Toolbar variant="dense">
            <Typography style={{margin: '0 auto'}} variant="h6" color="inherit" noWrap>
            {this.state.title}
            </Typography>
            {this.state.title.includes("Keranjang")?(<Button startIcon={<Done />} color="inherit" onClick={this.done}>Selesai</Button>):null}
          </Toolbar>
        </AppBar>
        {this.props.appContext.state.showLoading?<LinearProgress color="secondary"  variant="query" />:null}
        <Snackbar
            style={{marginTop:56}}
            anchorOrigin={{ vertical: 'top', horizontal :'right' }}
            open={this.props.appContext.state.showSnackBar}
            onClose={this.props.appContext.handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.props.appContext.state.textSnackBar}</span>}
        />
      </>
    );
  }
}


export default NavigationTop;