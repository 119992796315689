import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Gvar from './Gvar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from './Utils';
import { Box, Container, Link } from '@material-ui/core';
import logoFifapay from './assets/img/logo_fifapay.png';
import logoUnipos from './assets/img/logo_unipos.png';
import { ArrowRightAlt } from '@material-ui/icons';

class BindingAccount extends Component {

    constructor(props){
        super(props);
        this.state = ({
            list_group :[],
            title : 'Hubungkan Akun',
            showSnackBar : false,
            showLoading : false,
            showScreenSMSCode : false,
            no_hp : '',
            token : '',
            name : '',
            alamat: '',
            email : '',
            pin : '',
            password : '',
            smsCode : '',
            isLoggedIn : false,
            isValidToken : true
        });
    }

    generatePassword(passwordLength) {
        var numberChars = "0123456789";
        var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowerChars = "abcdefghiklmnopqrstuvwxyz";
        var allChars = numberChars + upperChars + lowerChars;
        var randPasswordArray = Array(passwordLength);
      randPasswordArray[0] = numberChars;
      randPasswordArray[1] = upperChars;
      randPasswordArray[2] = lowerChars;
      randPasswordArray = randPasswordArray.fill(allChars, 3);
      return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
    }
    
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }

    componentDidMount(){
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const name = urlParams.get('name');
        const alamat = urlParams.get('address');
        const phone = urlParams.get('phone');
        const email = urlParams.get('email');
        let password = this.generatePassword(12);
        this.setState({token:token, name: name, alamat:alamat, no_hp: phone, email: email, password: password, isLoggedIn:localStorage.getItem('data_member') !== null && localStorage.getItem('data_member') !== ''});
        this.checkToken(token);
    }

    checkToken(token){
        this.setState({showSnackBar: false, showLoading:true});
        fetch(Gvar.server+'index.php/member/check_token_acc_bind', {
            method: 'post',
            body : JSON.stringify({token:token})
        }).then(res=>res.json()).then(res => {
            this.setState({showLoading:false});
            // console.log(res)
            if(res.keterangan.includes("sudah terdaftar dan aktif")){
                // this.setState({ showSnackBar: true, textSnackBar :res.keterangan, showScreenSMSCode:true});
                Gvar.tabActive = 'dashboard';
                localStorage.setItem('data_member', JSON.stringify(res.data_member));  
                localStorage.setItem('is_logged_in', true);
                localStorage.setItem('token', res.token);
                localStorage.setItem('bindingAcc', true);
                Gvar.appContext.setState({isLoggedIn:true});
                this.setState({isLoggedIn:true})
            }else{
                if(res.keterangan.includes("token invalid")){
                    this.setState({ showSnackBar: true, textSnackBar :res.keterangan, isValidToken:false, isLoggedIn:false}); 
                }else if(res.keterangan.includes("belum terdaftar")){
                    this.setState({isValidToken:true, isLoggedIn:false}); 
                }
            }
        }).catch(err => {
            console.log(err.toString());
            this.setState({showLoading:false});
            this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }

    onSubmit(){
        if(this.state.pin === ''){
            this.refPiN.focus();
            this.setState({ showSnackBar: true, textSnackBar : 'Silakan buat PIN Transaksi lebih dulu'});return;
        }
        if(this.state.pin.length < 6){
            this.refPiN.focus();
            this.setState({ showSnackBar: true, textSnackBar : 'PIN Transaksi harus 6 digit angka'});return;
        }
        this.setState({showSnackBar: false, showLoading:true});
        var hash = Utils.getHash(this.state.name+this.state.no_hp+this.state.password);
        fetch(Gvar.server+'index.php/member/registrasi?nama='+this.state.name+'&alamat='+this.state.alamat+'&no_hp='+this.state.no_hp+'&password='+this.state.password+'&key='+hash+'&pin_transaksi='+this.state.pin+'&token='+this.state.token, {
            method: 'get',
        }).then(res=>res.json()).then(res => {
            this.setState({showLoading:false});
            // console.log(res)
            if(res.status === 'ok'){
                // this.setState({ showSnackBar: true, textSnackBar :res.keterangan, showScreenSMSCode:true});
                Gvar.tabActive = 'dashboard';
                localStorage.setItem('data_member', JSON.stringify(res.data_member));  
                localStorage.setItem('is_logged_in', true);
                localStorage.setItem('token', res.token);
                localStorage.setItem('bindingAcc', true);
                Gvar.appContext.setState({isLoggedIn:true});
                this.setState({isLoggedIn:true})
            }else{
                this.setState({ showSnackBar: true, textSnackBar :res.keterangan}); 
            }
        }).catch(err => {
            console.log(err.toString());
            this.setState({showLoading:false});
            this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }

    aktivasi(){
        this.setState({showSnackBar: false});
        if(this.state.smsCode === ""){
            this.setState({showSnackBar: true, textSnackBar :"Kode aktivasi tidak boleh kosong"});
            return;
        }

        this.setState({showLoading:true})
        
        var sms_code_hash = Utils.getHash(this.state.smsCode);
        var hash = Utils.getHash(this.state.no_hp+sms_code_hash);
        fetch(Gvar.server+'index.php/member/aktivasi?no_hp='+this.state.no_hp+'&sms_code='+sms_code_hash+'&key='+hash+'&app_name='+Gvar.appName, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            } 
        }).then(res=>res.json()).then(res => {
            this.setState({showLoading:false});
            if(res.status === 'ok'){
                this.setState({showScreenSMSCode:false, showSnackBar: true, textSnackBar :res.keterangan, isLoggedIn:true});
                //Login
                Gvar.tabActive = 'dashboard';
                localStorage.setItem('data_member', JSON.stringify(res.data_member));  
                localStorage.setItem('is_logged_in', true);
                localStorage.setItem('token', res.token);
                localStorage.setItem('bindingAcc', true);
                Gvar.appContext.setState({isLoggedIn:true});
            }else{
                this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
            }
        }).catch(err => {
            console.log(err.toString());
            this.setState({showLoading:false});
            this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }

    getTimestamp(){
        var d = new Date,
        dformat = [d.getFullYear(),
            this.pad(d.getMonth()+1),
            this.pad(d.getDate())].join('-')+' '+
                [this.pad(d.getHours()),
                this.pad(d.getMinutes()),
                this.pad(d.getSeconds())].join(':');
               return dformat;
    }

    pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    
    handleCloseSnackbar = () => {
        this.setState({showSnackBar:false});
    }

    render() {
        return (
            <>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <Typography variant="h6">
                            {this.state.title}
                        </Typography>
                    </Toolbar>
                    {this.state.showLoading?<LinearProgress color="secondary"  variant="query" />:null}
                </AppBar>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    <Container maxWidth="sm">
                        <Box my={8}>
                            <Card>
                                <CardContent>
                                    {this.state.isLoggedIn?(
                                        <>
                                            <Box style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                <img src={logoUnipos} style={{width:'15%', marginLeft:16}}/> <ArrowRightAlt/> <img src={logoFifapay} style={{width:'35%', marginRight:16}}/>
                                            </Box>
                                            <center>Akun berhasil terhubung ke {Gvar.appName}.</center>
                                            <center><Link style={{marginLeft:4}} href="/">Masuk Ke Menu Utama</Link></center>
                                        </>
                                    ):(
                                        <>
                                        {this.state.showScreenSMSCode?(
                                        <Grid item xs={12} container direction="column">
                                            <TextField
                                                id="inputSmsCode"
                                                label="Kode Aktivasi"
                                                type="number"
                                                name="namatoko"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                                value={this.state.smsCode}
                                                onChange={(e)=>this.setState({smsCode:e.target.value})}
                                            />

                                            <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                this.aktivasi();
                                            }}  size="large" color="primary" style={{marginTop : 10}}>
                                                Verifikasi No HP
                                            </Button>
                                        </Grid>
                                    ):(
                                        <>
                                            <Box style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                <img src={logoUnipos} style={{width:'15%', marginLeft:16}}/> <ArrowRightAlt/> <img src={logoFifapay} style={{width:'35%', marginRight:16}}/>
                                            </Box>
                                            <Grid item xs={12} container direction="column">
                                                {/* <TextField
                                                    id="inputNamaToko"
                                                    label="Nama Toko/Usaha"
                                                    type="text"
                                                    name="namatoko"
                                                    // autoComplete="email"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={this.state.name}
                                                    disabled
                                                    onChange={(e)=>this.setState({name:e.target.value})}
                                                />

                                                <TextField
                                                    id="inputNoHp"
                                                    label="Nomor HP"
                                                    type="number"
                                                    name="nomorhp"
                                                    // autoComplete="email"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={this.state.no_hp}
                                                    disabled
                                                    onChange={(e)=>this.setState({no_hp:e.target.value})}
                                                />
                                                
                                                <TextField
                                                    id="inputEmail"
                                                    label="Email"
                                                    type="email"
                                                    name="email"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={this.state.email}
                                                    disabled
                                                    onChange={(e)=>this.setState({email:e.target.value})}
                                                /> */}

                                                <TextField
                                                    id="inputPin"
                                                    label="Buat PIN Transaksi"
                                                    type="number"
                                                    name="pin_transaksi"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={this.state.pin}
                                                    disabled={!this.state.isValidToken}
                                                    inputRef={(ref)=>this.refPiN=ref}
                                                    onChange={(e)=>e.target.value.length <= 6?this.setState({pin:e.target.value}):null}
                                                />

                                                <Box>
                                                    <span style={{fontSize:12}}>Dengan menekan tombol submit berarti Anda menyetujui syarat dan ketentuan yang berlaku.</span>
                                                    <Link href='https://kulasedaya.com/syarat-dan-ketentuan-fifapay' style={{marginLeft:8, fontSize:12}}>Baca syarat dan ketentuan</Link>
                                                </Box>

                                                <Button disabled={this.state.showLoading || !this.state.isValidToken} variant="contained" type="submit" onClick={()=>{
                                                    this.onSubmit();
                                                }}  size="large" color="primary" style={{marginTop : 10}}>
                                                    Submit
                                                </Button>
                                            </Grid>
                                            </>
                                        )}
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Box>
                    </Container>
                </Grow>
                <Snackbar
                    style={{marginTop:54}}
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    key={`bottom,right`}
                    open={this.state.showSnackBar}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.textSnackBar}
                />
            </>
        );
    }
}

export default (BindingAccount);
