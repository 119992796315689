import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Gvar from './Gvar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from './Utils';
import { Box, Container, Link } from '@material-ui/core';

class Daftar extends Component {

    constructor(props){
        super(props);
        this.state = ({
            list_group :[],
            title : 'Daftar',
            showSnackBar : false,
            showLoading : false,
            showScreenSMSCode : false,
            no_hp : '',
            nama: '',
            alamat: '',
            email: '',
            pass: '',
            repass: '',
            pin: '',
            smsCode: ''
        });
    }

    componentDidMount(){
        // this.props.appContext.setState({showLoading:false})
        // this.getProdukGroup();
        this.refNama.focus()
    }

    onClickDaftar(){
        this.setState({showSnackBar: false});
        if(this.state.nama === ""){
            this.refNama.focus();
            this.setState({showSnackBar: true, textSnackBar :"Nama Toko/Usaha tidak boleh kosong"});
            return;
        }
        if(this.state.no_hp === ""){
            this.refNoHP.focus();
            this.setState({showSnackBar: true, textSnackBar :"No hp tidak boleh kosong"});
            return;
        }
        if(this.state.pass === ""){
            this.refPass.focus();
            this.setState({showSnackBar: true, textSnackBar :"Password tidak boleh kosong"});
            return;
        }
        if(this.state.repass === ""){
            this.refRepass.focus();
            this.setState({showSnackBar: true, textSnackBar :"Ulang Password tidak boleh kosong"});
            return;
        }

        if(this.state.repass !== this.state.pass){
            this.refRepass.focus();
            this.setState({showSnackBar: true, textSnackBar :"Ulang password belum sama"});
            return;
        }

        if(this.state.pin === ""){
            this.refPin.focus();
            this.setState({showSnackBar: true, textSnackBar :"PIN Transaksi tidak boleh kosong"});
            return;
        }

        this.setState({showLoading:true})
        
        var hash = Utils.getHash(this.state.nama+this.state.no_hp+this.state.pass);
        fetch(
            Gvar.server+`index.php/member/registrasi?nama=${this.state.nama}&email=${this.state.email}&alamat=${this.state.email}&no_hp=${this.state.no_hp}&no_ktp=&platform_type=WEB_APP&password=${this.state.pass}&pin_transaksi=${this.state.pin}&key=${hash}`, 
            { method: 'get'}
        )
        .then(res=>res.json())
        .then(res => {
            this.setState({showLoading:false});
            // console.log(res)
            if(res.status === 'ok'){
                this.setState({ showSnackBar: true, textSnackBar :res.keterangan, showScreenSMSCode:true});
            }else{
                this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
            }
        }).catch(err => {
            console.log(err.toString());
            this.setState({showLoading:false});
            this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }

    aktivasi(){
        this.setState({showSnackBar: false});
        if(this.state.smsCode === ""){
            this.refSmsCode.focus();
            this.setState({showSnackBar: true, textSnackBar :"Kode aktivasi tidak boleh kosong"});
            return;
        }

        this.setState({showLoading:true})
        
        var sms_code_hash = Utils.getHash(this.state.smsCode);
        var hash = Utils.getHash(this.state.no_hp+sms_code_hash);
        fetch(Gvar.server+'index.php/member/aktivasi?no_hp='+this.state.no_hp+'&sms_code='+sms_code_hash+'&key='+hash, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        }).then(res=>res.json()
            ).then(res => {
                this.setState({showLoading:false});
                // console.log(res)
                if(res.status === 'ok'){
                    this.setState({ showSnackBar: true, textSnackBar :res.keterangan + ", Sebentar lagi Anda akan diarahkan ke halaman Login."});
                    setTimeout(() => {
                        this.props.history.goBack()
                    }, 6000);
                }else{
                    this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
                }
            }
            ).catch(err => {
                console.log(err.toString());
                this.setState({showLoading:false});
                this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }
    
    handleCloseSnackbar = () => {
        this.setState({showSnackBar:false});
    }

    render() {
        return (
            <>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <IconButton edge="start" onClick={()=>{
                            this.props.history.goBack()
                        }} color="inherit" aria-label="menu">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {this.state.title}
                        </Typography>
                    </Toolbar>
                    {this.state.showLoading?<LinearProgress color="secondary"  variant="query" />:null}
                </AppBar>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    <Container maxWidth="sm">
                        <Box my={8}>
                            <Card>
                                <CardContent>
                                    {this.state.showScreenSMSCode?(
                                        <Grid item xs={12} container direction="column">
                                            <TextField
                                                id="inputSmsCode"
                                                label="Kode Aktivasi"
                                                type="number"
                                                name="namatoko"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                                onChange={(e)=>this.setState({smsCode:e.target.value})}
                                                value={this.state.smsCode}
                                                inputRef={(ref)=>this.refSmsCode = ref}
                                            />

                                            <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                this.aktivasi();
                                            }}  size="large" color="primary" style={{marginTop : 10}}>
                                                Verifikasi No HP
                                            </Button>
                                        </Grid>
                                    ):(
                                        <Grid item xs={12} container direction="column">
                                            <TextField
                                                id="inputNamaToko"
                                                label="Nama Member/Toko"
                                                type="text"
                                                name="namatoko"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:32}}
                                                onChange={(e)=>this.setState({nama:e.target.value})}
                                                value={this.state.nama}
                                                inputRef={(ref)=>this.refNama=ref}
                                            />
                                            
                                            <TextField
                                                id="inputAlamat"
                                                label="Alamat"
                                                type="text"
                                                name="Alamat"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:128}}
                                                onChange={(e)=>this.setState({alamat:e.target.value})}
                                                value={this.state.alamat}
                                                inputRef={(ref)=>this.refAlamat=ref}
                                            />
                                            
                                            <TextField
                                                id="inputemail"
                                                label="Email"
                                                type="email"
                                                name="email"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:32}}
                                                onChange={(e)=>this.setState({email:e.target.value})}
                                                value={this.state.email}
                                                inputRef={(ref)=>this.refEmail=ref}
                                            />

                                            <TextField
                                                id="inputNoHp"
                                                label="Nomor HP"
                                                type="number"
                                                name="nomorhp"
                                                // autoComplete="email"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:14}}
                                                onChange={(e)=>this.setState({no_hp:e.target.value})}
                                                value={this.state.no_hp}
                                                inputRef={(ref)=>this.refNoHP=ref}
                                            />

                                            <TextField
                                                id="inputPassword"
                                                label="Password"
                                                type="password"
                                                name="password"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:32}}
                                                onChange={(e)=>this.setState({pass:e.target.value})}
                                                value={this.state.pass}
                                                inputRef={(ref)=>this.refPass=ref}
                                            />

                                            <TextField
                                                id="inputRePassword"
                                                label="Ulangi Password"
                                                type="password"
                                                name="password"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:32}}
                                                onChange={(e)=>this.setState({repass:e.target.value})}
                                                value={this.state.repass}
                                                inputRef={(ref)=>this.refRepass=ref}
                                            />

                                            <TextField
                                                id="inputpin"
                                                label="Buat PIN Transaksi"
                                                type="number"
                                                name="pin"
                                                margin="normal"
                                                variant="outlined"
                                                inputProps={{maxLength:6}}
                                                onChange={(e)=>this.setState({pin:e.target.value})}
                                                value={this.state.pin}
                                                inputRef={(ref)=>this.refPin=ref}
                                            />

                                            <Box>
                                                Dengan mendaftar berarti Anda menyetujui syarat dan ketentuan yang berlaku.
                                                <Link href='https://kulasedaya.com/syarat-dan-ketentuan' style={{marginLeft:8}}>Baca syarat dan ketentuan</Link>
                                            </Box>

                                            <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                this.onClickDaftar();
                                            }}  size="large" color="primary" style={{marginTop : 10}}>
                                                Daftar
                                            </Button>
                                        </Grid>
                                    )}
                                </CardContent>
                            </Card>
                        </Box>
                    </Container>
                </Grow>
                <Snackbar
                    style={{marginTop:54}}
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    key={`bottom,right`}
                    open={this.state.showSnackBar}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.textSnackBar}
                />
            </>
        );
    }
}

export default (Daftar);
