import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import Gvar from './Gvar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from './Utils';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    return (
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                Gvar.tabActive = 'pengaturan';
                context.props.history.goBack()
            }} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
            {context.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
        </AppBar>
    );
}

class PengaturanGantiPassword extends Component {
    
    state = {
        inputPasswordLama : "",
        inputPasswordBaru : "",
        inputPasswordUlang : "",
        title : 'Ganti Password'
    }
    constructor(props){
        super(props);
    }

    update(){
        var data_member = localStorage.getItem('data_member');
        var passwordLama = document.getElementById("password-lama");
        var passwordBaru = document.getElementById("password-baru");
        var passwordUlang = document.getElementById("password-ulang");
        this.setState({showLoading:true})
        var bodyJson = { 
            id_member: JSON.parse(data_member).id,
            password_lama: Utils.getHash(passwordLama.value),
            password_baru: Utils.getHash(passwordBaru.value),
            password_baru_ulang: Utils.getHash(passwordUlang.value),
            token: Utils.getMyToken()
        }
        console.log(bodyJson);
        fetch(Gvar.server+'index.php/pengaturan/gantipassword', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                this.setState({ showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                this.setState({showLoading:false});
                this.setState({
                    inputPasswordLama : "",
                    inputPasswordBaru : "",
                    inputPasswordUlang : ""
                });

            }
            ).catch(err => {
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
                this.setState({showLoading:false})
             })
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    handleChangeInputPassLama = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 20);

        this.setState({
            inputPasswordLama : textReturn
        });
    };
    handleChangeInputPassBaru = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 20);

        this.setState({
            inputPasswordBaru : textReturn
        });
    };
    handleChangeInputPassUlang = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 20);

        this.setState({
            inputPasswordUlang : textReturn
        });
    };

    render() {
        const polygon = (
            <>
            <Card style={{marginTop : 20}}>
                <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                        <TextField
                            onChange={this.handleChangeInputPassLama}
                            value={this.state.inputPasswordLama}
                            id="password-lama"
                            label="Password Lama"
                            type="password"
                            name="nomorhp"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            onChange={this.handleChangeInputPassBaru}
                            value={this.state.inputPasswordBaru}
                            id="password-baru"
                            label="Password Baru"
                            type="password"
                            rowsMax="10"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            onChange={this.handleChangeInputPassUlang}
                            value={this.state.inputPasswordUlang}
                            id="password-ulang"
                            label="Ketik Ulang Password Baru"
                            type="password"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <Button variant="contained" type="submit" onClick={()=>{
                                this.update()
                            }}  size="large" color="primary" style={{marginTop : 10}}>
                            Simpan
                        </Button>
                    </Grid>
                </CardContent>
            </Card> 
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{marginTop:75}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}

export default PengaturanGantiPassword;
