import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from './Gvar';
import { sha256 } from 'js-sha256';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Utils, {loadProfile} from './Utils';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    var currentURL = window.location.href;
    // loadProfile();
    var jenis = currentURL.split("jenis=")[1];
    return (
        <>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </>
    );
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProdukPembayaran extends Component {
    state = {
        jenisKartu : '',
        showSnackBar : false,
        textSnackBar : '',
        listProduk : [],
        showConfirmDialog : false,
        showConfirmPIN : false,
        title : '',
        textMessage : '',
        transactionCompleted : false,
        pinTransaksi : ''
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.jenis = "";
        this.titlePage = "";
        var currentURL = window.location.href;
        // loadProfile();
        this.data_member = JSON.parse(localStorage.getItem('data_member'));
        this.jenis = currentURL.split("jenis=")[1];
        this.group = decodeURI(currentURL.split("group=")[1].split("&jenis=")[0]);
        this.titlePage = this.group;
        this.refPIN = React.createRef();
        this.dataConfirm = {
            nama_produk : ''
        }
    }

    componentDidMount(){
        loadProfile();
        this.setState({
            title:this.titlePage
        });
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    handleClickOpenConfirm(rowData){
        // console.log("rowData",rowData);
        // loadProfile();
        this.dataConfirm = rowData;
        // this.sisaSaldo = parseFloat(Gvar.saldo)-parseFloat(rowData.h_jual)
        // this.setState({showConfirmDialog:true});
    };
    
    handleCloseConfirm = () => {
        this.setState({showConfirmDialog:false});
    };

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    deteksiOperator(){
        this.noTujuan = document.getElementById("inputNoTujuan").value;
        // console.log(nohp.value);
        if(this.noTujuan.length >= 1){
            if(this.state.listProduk.length <= 0){
                this.getDataProduk();
            }
        }else{
            this.setState({listProduk:[]})
        }
    }

    getDataProduk(){
        this.setState({showLoading:true})
        var bodyJson = {jenis:this.jenis,group : this.group,id_member:this.data_member.id,token:Utils.getMyToken()}
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/produk/nonprefix', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                this.setState({listProduk:res.data})
                this.setState({showLoading:false});
            }
            ).catch(err => {
                this.setState({showLoading:false});
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }

    sendTransaction (isInq){
        var noTujuan = this.noTujuan;
        var kodeProduk = this.dataConfirm.kode_produk;
        if(isInq){
            kodeProduk = kodeProduk.replace("PAY","INQ");
            var pinTransaksi = document.getElementById('inputPINTransaksi').value;
            this.state.pinTransaksi = pinTransaksi;
        }
        if(noTujuan.length > 25){
            this.setState({ showSnackBar: true, textSnackBar : "Maksimal 25 digit pada Nomor Pelanggan"}); return;
        }
        
        if(noTujuan === ""){
            this.setState({ showSnackBar: true, textSnackBar : "Nomor Pelanggan belum diisi"}); return;
        }
        if(kodeProduk === ""){
            this.setState({ showSnackBar: true, textSnackBar : "nominal tujuan belum diisi"}); return;
        }
        if(isInq){
            if(pinTransaksi === ""){
                this.setState({ showSnackBar: true, textSnackBar : "pin transaksi belum diisi"}); return;
            }
        }
        this.setState({showLoading:true})
        var bodyJson = { 
            id_member: this.data_member.id,
            no_tujuan: noTujuan,
            kode_produk:kodeProduk,
            pin_transaksi:Utils.getHash(this.state.pinTransaksi),
            trx_source : 'WEB_APP',
            token : Utils.getMyToken()
        }
        console.log(bodyJson);
        fetch(Gvar.server+'index.php/transaksi', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                // loadProfile();
                //console.log(res)
                // this.setState({listProduk:res})
                
                this.setState({showLoading:false})
                
                if(isInq){
                    if(res.data.status === "SUKSES"){
                        // document.getElementById("inputNoTujuan").value = "";
                        this.setState({showConfirmDialog:true,textMessage:res.data.pesan});
                    }else{
                        this.setState({ showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    }
                }else{
                    // this.setState({ showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    if(res.data.status === "SUKSES"){
                        document.getElementById("inputNoTujuan").value = "";
                        this.setState({showConfirmDialog:true,textMessage:res.data.pesan,transactionCompleted:true});
                    }else{
                        this.setState({ showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    }
                }
            }
            ).catch(err => {
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
                this.setState({showLoading:false})
             })
    }

    copy(){
        var range = document.createRange();
        range.selectNode(document.getElementById("textMessageID"));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect
        // this.setState({ showSnackBar: true, textSnackBar :"Teks telah disalin"});
        alert('Teks telah disalin');
    }

    render() {
        const polygon = (
            <>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{this.state.transactionCompleted?'Transaksi Selesai':'Detail Tagihan'}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div>
                            <pre id="textMessageID" style={{fontFamily:'monospace'}}>{this.state.textMessage.replace(/<br>/g,"\n")}</pre>
                            {this.state.transactionCompleted?(
                                <a href="#" onClick={()=>{
                                    this.copy()
                                }}>Salin Teks</a>
                            ):null}
                        </div>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    {this.state.transactionCompleted?(
                        <div>
                            <Button onClick={this.handleCloseConfirm} color="primary">
                                OK
                            </Button>
                        </div>
                    ):(
                        <div>
                            <Button onClick={this.handleCloseConfirm} color="primary">
                                Batal
                            </Button>
                            <Button onClick={()=>{
                                this.setState({showConfirmPIN:false,showConfirmDialog:false})
                                this.sendTransaction(false);
                            }} color="primary">
                                Bayar
                            </Button>
                        </div>
                    )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showConfirmPIN}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"PIN Transaksi"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField
                                id="inputPINTransaksi"
                                label="PIN Transaksi"
                                type="password"
                                name="password"
                                margin="normal"
                                variant="outlined"
                                autoComplete="none"
                                autoFocus
                                innerRef={this.refPIN}
                            />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false,transactionCompleted:false});
                        this.sendTransaction(true);
                        document.getElementById('inputPINTransaksi').value ="";
                    }} color="primary">
                        Cek Tagihan
                    </Button>
                    </DialogActions>
                </Dialog>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                            <TextField
                                id="inputNoTujuan"
                                label="Nomor Pelanggan"
                                type="number"
                                name="nomorhp"
                                autoComplete="none"
                                margin="normal"
                                variant="outlined"
                                onKeyUp={()=>this.deteksiOperator()}
                            />
                            
                            {/* <FormControl>
                                <InputLabel htmlFor="kodeProduk">Nominal</InputLabel>
                                <Select
                                    native
                                    value={this.state.kodeProduk}
                                    onChange={this.handleChange('kodeProduk')}
                                    inputProps={{
                                    name: 'kodeProduk',
                                    id: 'kodeProduk',
                                    }}
                                >
                                    <option value="" />
                                    {this.state.listProduk.map(data => (
                                    <option key={data.id} value={data.kode_produk}>{data.nama_produk} ({data.h_jual})</option>
                                    ))}
                                </Select>
                            </FormControl> */}

                            {/* <TextField
                                id="inputPINTransaksi"
                                label="PIN Transaksi"
                                type="password"
                                name="password"
                                margin="normal"
                                variant="outlined"
                            /> */}

                            {/* <Button variant="contained" type="submit" onClick={()=>{
                                this.sendTransaction()
                            }}  size="large" color="primary" style={{margin : 10}}>
                                Kirim
                            </Button> */}
                        </Grid>
                    </CardContent>
                </Card> 
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
                {this.state.listProduk.map(data => { 
                    var price = (data.h_jual);
                    return (
                    <Card onClick={()=>{
                        // this.setState({ showSnackBar: false})
                        // this.handleClickOpenConfirm(data)
                        this.state.pinTransaksi = '';
                        this.dataConfirm = data;
                        this.setState({showConfirmPIN:true});
                        setTimeout(() => {
                            document.getElementById('inputPINTransaksi').focus();
                        }, 300);
                    }} key={data.kodeProduk} style={{marginTop : 20}}>
                        <CardContent style={{position:'relative'}}>
                            <span style={{fontSize:18}}>
                                {data.nama_produk}    
                            </span> <br></br>
                            <span>
                            {data.keterangan_produk}
                            </span>
                            {/* <span style={{position:'absolute',top:16,right:30}}>
                                {price.startsWith('-')?'Fee ':''}{Utils.formatRupiah(price,'.').replace('-','')} 
                            </span> */}
                            <span style={{position:'absolute',top:16,right:0}}>
                            <ArrowRightIcon></ArrowRightIcon> 
                            </span>
                        </CardContent>
                    </Card>    
                // <option key={data.id} value={data.kode_produk}>{data.nama_produk} ({data.h_jual})</option>
                )}
                )}
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:56}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}


export default ProdukPembayaran;
